import React from 'react'
import yyt from '../assets/img/yyt-logo.png'
import ps4Symbol from '../assets/img/logo-ps4-symbol.svg'
import ps4 from '../assets/img/logo-ps4.svg'
import nisaIcon from '../assets/img/nisa.png'
import esrbIcon from '../assets/img/esrb-rating-rp.svg'
import esrbIconE from '../assets/img/logo-esrb-e.svg'
import switchIcon from '../assets/img/logo-nintendo.svg'


export const Footer = () => {
    const [email, setEmail] = React.useState('')
    return (
        <footer id="footer" className="flex col">
            <form action="https://fallenlegiongame.us12.list-manage.com/subscribe/post?u=11e9ed8e9514004aae3931df3&amp;id=681b3def29" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="flex" target="_blank" novalidate="">

                        
<h3 className="uppercase news-letter" style={{color:'white'}}>Get Fallen Legion News Delivered To You</h3>                            
            
    <input type="email" defaultValue="" name="EMAIL" className="nl-email" id="mce-EMAIL" size="50" placeholder="email address" required=""/>
            
            <div className="mt-40 mt-xxs-20">
                <input type="submit" value="Join Newsletter" name="subscribe" id="mc-embedded-subscribe" className="nl-btn"/>
            </div>
            </form>
            {/* <div className="sub-box flex col pad"> */}
                

                {/* <div className="flex row wrap pad">
                    <a href="http://www.twitter.com/fallenlegionps" target="_blank" className="track-link" data-label="Twitter"><i className="fab  fa-twitter social-icon"></i></a>

                    <a href="http://www.facebook.com/fallenlegiongame" target="_blank" className="track-link" data-label="Facebook"><i className="fab   fa-facebook-f social-icon"></i></a>

                    <a href="https://www.instagram.com/nisamerica/" target="_blank" className="track-link" data-label="Instagram"><i className="fab  fa-instagram social-icon"></i></a>

                    <a href="https://www.youtube.com/user/NISAmerica" target="_blank" className="track-link" data-label="YouTube"><i className="fab  fa-youtube social-icon"></i></a>

                    <a href="https://www.twitch.tv/nisamerica" target="_blank" className="track-link" data-label="Twitch"><i className="fab  fa-twitch social-icon"></i></a>

                    <a href="https://discord.gg/nisa" target="_blank" className="track-link" data-label="Discord"><i className="fab  fa-discord social-icon"></i></a>

                </div> */}

                {/* <p>Subscribe to get the latest information!</p> */}
                
                {/* <div className="mailchimp">
                    <div id="mc_embed_signup">

                        <form action="https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

                            <div id="mc_embed_signup_scroll" className='flex row'>

                        

                            <input style={{width:'150px', height:'32px', borderRadius:'5px', border:'solid white 1px', fontSize:'16px'}} type="email" onChange={(e)=>setEmail(e.currentTarget.value)} value={email} name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required/>


                            <div 
                            style={{position: 'absolute', left: '-5000px'}} 
                            aria-hidden="true">
                                <input type="text" name="b_e8fc423eff95099d1ffcc6009_349f7d20f9" tabIndex="-1" defaultValue=""/>
                            </div>

                                <button style={{borderRadius:'2px', textAlign:'center'}} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="top-btn track-link" data-label="Subscribe">
                                Subscribe
                                </button>

                            </div>

                        </form>
                    </div>

                </div> */}
                {/* <button style={{width:'initial', fontSize:'1em'}} className='top-btn'>Subscribe</button> */}
            {/* </div> */}
            {/* <div className="esrbs flex row">
                <div className="esrb-box flex">
                    <img width="170px" src={esrbIconE} alt=""/>
                    <p className="esrb-subtitle" style={{color:'white'}}>Fallen Legion: Rise to Glory</p>
                </div>
                <div className="esrb-box flex">
                    <img width="170px" src={esrbIcon} alt=""/>
                    <p className="esrb-subtitle" style={{color:'white'}}>Fallen Legion: Revenants</p>
                </div>
            </div> */}
            <div className="flex col footer-bottom">
                
                {/* <div className="brands flex row wrap">
                    <img src={ps4Symbol} alt="" className="brand-icon" style={{height:'40px'}}/>
                    <img src={ps4} alt="" className="brand-icon-sm"/>
                    <img src={switchIcon} alt="" className="brand-icon"/>
                    <img src={yyt} alt="" className="brand-icon"/>
                    <a href="http://nisamerica.com/" target="_blank"><img src={nisaIcon} alt="" className="nisa-icon"/></a> 

                </div> */}
                <p className="footer-text" style={{padding:'0', paddingTop:0,textAlign:'center'}}>
                <div className="flex row wrap pad" style={{margin:"16px"}}>
                    <a href="http://www.twitter.com/fallenlegion" target="_blank" className="track-link" data-label="Twitter"><i className="fab  fa-twitter social-icon"></i></a>

                    <a href="http://www.facebook.com/fallenlegiongame" target="_blank" className="track-link" data-label="Facebook"><i className="fab   fa-facebook-f social-icon"></i></a>

                    {/* <a href="https://www.instagram.com/nisamerica/" target="_blank" className="track-link" data-label="Instagram"><i className="fab  fa-instagram social-icon"></i></a>

                    <a href="https://www.youtube.com/user/NISAmerica" target="_blank" className="track-link" data-label="YouTube"><i className="fab  fa-youtube social-icon"></i></a>

                    <a href="https://www.twitch.tv/nisamerica" target="_blank" className="track-link" data-label="Twitch"><i className="fab  fa-twitch social-icon"></i></a>

                    <a href="https://discord.gg/nisa" target="_blank" className="track-link" data-label="Discord"><i className="fab  fa-discord social-icon"></i></a> */}

                </div>
                ©YummyYummyTummy, Inc. “Fallen Legion” is a registered trademark of YummyYummyTummy, Inc.
                    {/* ©YummyYummyTummy, Inc. Licensed to and published by NIS America, Inc.
                    <br/>
                    <span style={{fontFamily:'SCE'}}>2</span>”, "PlayStation" and “<span style={{fontFamily:'SCE'}}>=</span>” are trademarks or registered trademarks of Sony Interactive Entertainment Inc. 
                    <br/>
                    Nintendo Switch is a trademark of Nintendo.
                    <br/>
                    The rating icon is a trademark of the Entertainment Software Association.
                    <br/>
                    All other trademarks are properties of their respective owners. */}
                </p>
            </div>
        </footer>


    )
}
