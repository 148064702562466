import React from "react";
import CrossFade from "react-crossfade-image";
import flCover from "./assets/img/fl-cover.png";
import flCover2 from "./assets/img/fl2-cover.png";
import flCover2Title from "./assets/img/fl2-cover-title.png";
import flCoverTitle from "./assets/img/fl-cover-title.png";
import nisa from "./assets/img/nisa.png";
import bg from "./assets/img/bg.png";
import bg2 from "./assets/img/illust04.PNG";
import bg2M from "./assets/img/illust04-mobile.png";
import bg3 from "./assets/img/bg3.png";
import bg3M from "./assets/img/bg3-mobile.png";

import collCover from "./assets/img/collection-cover.jpg";
import collCoverTitle from "./assets/img/collection-cover-title.png";
import smallCover3 from "./assets/img/coll-cover-small.jpg";

// Notes:
// 1) Need meta description
// 2) Pontential changes to bg size code
// 3) mailchimp form links to prinny subscription form.
// 4) possibly add text somewhere that says "click here to go to website" etc

import smallCover1 from "./assets/img/fl-cover-small.png";
import smallCover2 from "./assets/img/fl2-cover-small.png";
import collection from "./assets/img/collection.png";

import { Footer } from "./components/Footer";
import "animate.css";
import "./App.css";
import "./mq.css";

function App() {
  const [overlay, setOverlay] = React.useState("transparent");
  const [currentBG, setCurrentBG] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const backgroundArray = [bg, bg2, bg3];

  const title = {
    transparent: ` `,
    "#a6882f": "Fallen Legion: Rise to Glory",
    "#911b23": "Fallen Legion: Revenants",
    "#4e5072": "Fallen Legion: Rise to Glory / Fallen Legion Revenants",
  };

  const getWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const changeBG = () => {
    if (currentBG === backgroundArray.length - 1) {
      setCurrentBG(0);
    } else {
      setCurrentBG(currentBG + 1);
    }
  };

  // React.useEffect(()=>
  // {
  //   window.addEventListener("resize", ()=>setScreenWidth(window.innerWidth))
  //   return ()=> {window.removeEventListener("resize", ()=>setScreenWidth(window.innerWidth))}
  // },[])

  React.useEffect(() => {
    let timeOut = setInterval(() => {
      changeBG();
    }, 8000);

    return () => {
      clearInterval(timeOut);
    };
  }, [currentBG]);

  return (
    <div className="App flex start">
      {/* {backgroundArray[currentBG]} */}

      <header id="header" className="flex row start">
        {/* <img src={nisa} alt="NIS America logo" id="nisa"/> */}
        <h1 id="header-title">
          <span style={{ color: "#a6882f" }}>Fallen Legion</span> | Portal
        </h1>
      </header>
      <main id="main" className="flex col start">
        <div
          className="buffer-of-70px hide-on-desktop"
          style={{ height: "70px" }}
        ></div>
        <div
          className="bgs"
          style={{ backgroundImage: `url(${bg2})`, backgroundSize: "cover" }}
        >
          <img
            className="bg-overlay"
            src={bg}
            style={{
              // width:'100vw',
              // height:'auto',
              filter: `grayscale(${overlay !== "transparent" ? "0" : "0%"})`,
              opacity: currentBG === 0 ? 1 : 0,
              transform: `scale(${currentBG === 0 ? "1.2" : "1"})`,
            }}
          ></img>
          <img
            className="bg-overlay"
            src={screenWidth < 650 ? bg2M : bg2}
            style={{
              // width:'auto',
              // height:'100%',
              filter: `grayscale(${overlay !== "transparent" ? "0" : "0%"})`,
              opacity: currentBG === 1 ? 1 : 0,
              transform: `scale(${currentBG === 1 ? "1.2" : "1"})`,
            }}
          ></img>
          <img
            className="bg-overlay"
            src={screenWidth < 650 ? bg3M : bg3}
            style={{
              // width:'auto',
              // height:'100vh',
              filter: `grayscale(${overlay !== "transparent" ? "0" : "0%"})`,
              opacity: currentBG === 2 ? 1 : 0,
              transform: `scale(${currentBG === 2 ? "1.2" : "1"})`,
            }}
          ></img>
          {/* <img className="bg-overlay" src={bg4} style={{filter: `grayscale(${overlay!=="transparent"?"0":"0%"})`,opacity:currentBG===3?1:0, transform:`scale(${currentBG===3?'1':'.98'})`}}></img>
          <img className="bg-overlay" src={bg5} style={{filter: `grayscale(${overlay!=="transparent"?"0":"0%"})`,opacity:currentBG===4?1:0, transform:`scale(${currentBG===4?'1':'.98'})`}}></img>
          <img className="bg-overlay" src={bg6} style={{filter: `grayscale(${overlay!=="transparent"?"0":"0%"})`,opacity:currentBG===5?1:0, transform:`scale(${currentBG===5?'1':'.98'})`}}></img> */}
        </div>

        <div
          className="page-overlay"
          style={{
            backgroundColor:
              overlay === "transparent" ? "transparent" : overlay,
          }}
        ></div>

        <section
          className="small-covers flex animate__animated animate__fadeIn animate__delay-1s hide-on-desktop"
          style={{ marginTop: "8px" }}
        >
          {/* <div className="container flex">
            <img
              width={400}
              src={smallCover1}
              alt="Fallen Legion part 1 cover art"
              // className="small-cover"
            />
            <div className="mobile-banner">
              PS4&trade;, PS Vita, Nintendo Switch&trade;, and Steam&reg;
            </div>
          </div> */}
          <div className="container flex">
            <a href="https://fallenlegiongame.com/rise-to-glory">
              <img
                src={smallCover1}
                alt="Fallen Legion part 1 cover art"
                className="small-cover"
              />
            </a>

            <div className="mobile-banner">
              PS4&trade;, PS Vita, Nintendo Switch&trade;, and Steam&reg;
            </div>
          </div>

          <div
            className="buffer-of-30px hide-on-desktop"
            style={{ height: "30px" }}
          ></div>

          <div className="container flex">
            <a href={"https://fallenlegiongame.com/revenants"}>
              <img
                src={smallCover2}
                alt="Fallen Legion part 2: Revenants cover art"
                className="small-cover"
              />
            </a>
            <div className="mobile-banner">
              PS4&trade; and Nintendo Switch&trade;
            </div>
          </div>

          <div
            className="buffer-of-30px hide-on-desktop"
            style={{ height: "30px" }}
          ></div>

          <div className="container flex">
            <a href={"https://fallenlegiongame.com/collection"}>
              <img
                src={smallCover3}
                alt="Fallen Legion part 2: Revenants cover art"
                className="small-cover"
              />
            </a>
            <div className="mobile-banner">PS5&trade;, Xbox and PC</div>
          </div>
        </section>

        <section
          className="covers flex row wrap apart hide-on-mobile"
          onMouseEnter={() => console.log("enetered covers")}
        >
          <a href="https://fallenlegiongame.com/rise-to-glory">
            <Cover
              cover={flCover}
              move="-55px"
              title={flCoverTitle}
              setOverlay={setOverlay}
              overlayColor="#a6882f"
              text="PS4&trade;, PS  Vita, Nintendo Switch&trade;, and Steam&reg;"
            />
          </a>

          <a href="https://fallenlegiongame.com/revenants">
            <Cover
              move="-65px"
              cover={flCover2}
              title={flCover2Title}
              setOverlay={setOverlay}
              overlayColor="#911b23"
              text="PS4&trade; and Nintendo Switch&trade;"
            />
          </a>
          <a href="https://fallenlegiongame.com/collection">
            <Cover
              move="-65px"
              cover={collCover}
              title={collCoverTitle}
              setOverlay={setOverlay}
              overlayColor="#4e5072"
              text="PS5&trade;,  Xbox and PC"
            />
          </a>
        </section>
        <div className="flex">
          <h1
            key={overlay}
            id="title"
            className={`animate__animated animate__fadeIn`}
          >
            {title[overlay]}
          </h1>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;

const Cover = (props) => {
  const [hover, setHover] = React.useState(false);

  return (
    <div
      className="cover-container"
      onMouseEnter={() => {
        props.setOverlay(props.overlayColor);
        setHover(true);
      }}
      onMouseLeave={() => {
        props.setOverlay("transparent");
        setHover(false);
      }}
      style={{
        backgroundImage: `url(${props.cover})`,
        borderColor: hover ? "white" : "#a6882f",
      }}
    >
      <Banner text={props.text} />

      <div
        className="overlay flex"
        style={{
          backgroundColor: hover ? "rgba(0, 0, 0, 0.400)" : "rgba(0, 0, 0, 0)",
        }}
      >
        <img
          src={props.title}
          alt="Fallen Legion cover title text art"
          className="cover-title"
          style={{
            transform: `scale(${hover ? 1.1 : 1}) translateY(${
              hover ? props.move : "-50px"
            }) `,
          }}
        />
      </div>
    </div>
  );
};

const Banner = (props) => {
  return (
    <div className="anim-box">
      <div className="banner flex row">
        <h3>{props.text}</h3>
      </div>
    </div>
  );
};
